import React from 'react';
// import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo_telugu from '../../Assets/Logo/TELANGANANIJAMLOGO.png';
import logo_english from '../../Assets/Logo/v6English.png';
import logo_hindi from '../../Assets/Logo/v6Hindi.png';
import logo_national from '../../Assets/Logo/V6NATIONAL.png';
import logo_bharath from '../../Assets/Logo/V6BHARAT.png';
import { LANGUAGE } from '../../Config/Config';

const HeaderPage = () => {
//console.log(language);
    let logo = "";

    if(LANGUAGE === "HINDI") 
            logo = logo_bharath;
    else if(LANGUAGE === "ENGLISH")
        logo = logo_national;
    else
        logo = logo_telugu;
    

    const navigate = useNavigate();

    const getnavigate = () => {
        navigate('/home');
     }

    return(
        <section className='position-fixed w-100 z-index-999'>
            <div className={LANGUAGE === "TELUGU"? "text-center py-2 bg_color_bule" : "text-center py-2 bg_color_white"}>
                <img className='cursor_pointer object_fit_cover' alt='logo' src={logo} width={250} height={65} onClick={() => {getnavigate()}}/>
                {/* <h1 className='cursor_pointer' onClick={() => {getnavigate()}}>Telangana Nijam</h1> */}
            </div>
        </section>
    )
}

export default HeaderPage;